import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import './Modal.scss';

const Modal = ({ onClose, ...props }) => {
  const overlayRef = useRef();

  useEffect(() => {
    const handleEscapeKey = event => {
      if (event.keyCode === 27) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  const handleOverlayClick = (e) => {
    if (e.target === overlayRef.current) {
      onClose();
    }
  }

  return ReactDOM.createPortal(
    <div
      className={`modal-overlay ${props.className}`}
      ref={overlayRef}
      onClick={handleOverlayClick}
    >
      <div className="modal">
        <button className="modal-close btn inverted" onClick={onClose} title="Close">
          <Icon path={mdiClose} size={1} />
        </button>
        <div className="modal-content">{props.children}</div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
