import { createContext, useReducer, useContext } from 'react';

export const initialStore = {
  uploadInProgress: false,
  objectDetectionInProgress: false,
  showingOriginal: false,
  showLegal: false,
  showShare: false,
  showShareResult: false,
  activeLayer: undefined,
  imageData: undefined,
  objectData: undefined,
  layerData: undefined,
};

export const Store = createContext(initialStore);

export const useStore = () => useContext(Store);

export function StoreProvider(props) {
  const [store, updateStore] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    initialStore
  );

  return <Store.Provider value={{ store, updateStore }}>{props.children}</Store.Provider>;
}
