import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiShareVariant } from '@mdi/js';
import { sendAnalytics, EVENTS } from '../../utils/analytics';
import { useStore } from '../../utils/store';
import Share from '../Share';

import logo from './logo.svg';
import './Header.scss';

const Header = () => {
  const { store, updateStore } = useStore();

  const showShare = () => {
    sendAnalytics(EVENTS.product_share);
    updateStore({ showShare: true });
  };

  const hideShare = () => {
    updateStore({ showShare: false, showShareResult: false });
  };

  return (
    <header className="header">
      <div className="header-inner">
        <Link className="brand" to="/">
          <img className="brand-logo" src={logo} alt="" />
          <span className="brand-name">Cloudinary</span>
          <span className="brand-labs">LABS</span>
          <span className="brand-slogan">Erase it!</span>
        </Link>

        <button className="share" onClick={showShare}>
          <Icon path={mdiShareVariant} size={1} />
        </button>

        {(store.showShare || store.showShareResult) && <Share onClose={hideShare} />}
      </div>
    </header>
  );
};

export default Header;
