import React, { useRef, useState, useEffect } from 'react';
import useImage from 'use-image';
import { Stage, Image, Layer, Group, Text } from 'react-konva';
import { useStore } from '../../utils/store';
import { getImagePath } from '../../utils/cloudinary';
import ImageLayer from '../ImageLayer';
import ObjectLayer from '../ObjectLayer';

import './Canvas.scss';
import { isTouch } from '../../utils/isTouch';

const Canvas = () => {
  const { store } = useStore();
  const canvasRef = useRef(null);
  const basePublicId = store.imageData.publicId;
  const [image] = useImage(getImagePath(basePublicId, 't_resize'));
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [imageData, setImageData] = useState();

  // Set canvas size
  useEffect(() => {
    const handleResize = () => {
      const { width, height } = canvasRef.current.getBoundingClientRect();

      setContainerSize({ width, height });
    }

    if (!canvasRef.current) {
      return;
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [canvasRef]);

  // Set base image size & position
  useEffect(() => {
    if (!image || !containerSize) {
      return;
    }

    const scale = Math.min(
      1,
      Math.min(containerSize.width / image.width, containerSize.height / image.height)
    ); // <= 1

    setImageData({
      scale,
      publicId: basePublicId,
      width: image.width * scale,
      height: image.height * scale
    });
  }, [containerSize, image, basePublicId]);

  const tags = store.objectData?.tags.slice().sort((a, b) => {
    // On mobile, show the active layer on top
    if (isTouch) {
      if (store.activeLayer === a.itemIndex) {
        return 1;
      } else if (store.activeLayer === b.itemIndex) {
        return -1;
      }
    }
    return 0;
  });

  const layer = store.layerData && store.layerData.slice(-1)[0]; // just the last one

  return (
    <div ref={canvasRef} className="canvas">
      {imageData && (
        <Stage width={imageData.width} height={imageData.height}>
          <Layer>
            <Image
              image={image}
              width={imageData.width}
              height={imageData.height}
              preventDefault={false}
            />

            {layer && (
              <ImageLayer layer={layer} width={imageData.width} height={imageData.height} />
            )}

            {tags &&
              tags.map((item, i) => (
                <ObjectLayer
                  key={item.itemIndex}
                  itemIndex={item.itemIndex}
                  tag={item.tag}
                  item={item}
                  imageData={imageData}
                />
              ))}

            {store.showingOriginal && (
              <Group x={0} y={0}>
                <Image
                  image={image}
                  width={imageData.width}
                  height={imageData.height}
                  preventDefault={false}
                />
                <Text
                  text="Original"
                  x={20}
                  y={20}
                  fontSize={20}
                  fontStyle="bold"
                  fill="rgba(255, 255, 255, 0.5)"
                  shadowOffset={{ x: 1, y: 1 }}
                  shadowOpacity={0.5}
                />
              </Group>
            )}
          </Layer>
        </Stage>
      )}
    </div>
  );
};

export default Canvas;
