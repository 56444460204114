import React, { useState, useEffect } from 'react';
import { Arc } from 'react-konva';
import Konva from 'konva';

import './SpinCircle.scss';

const SpinCircle = ({ x, y, size }) => {
  const [angle, setAngle] = useState(0);

  useEffect(() => {
    const anim = new Konva.Animation(frame => {
      setAngle(angle => angle + 8);
    }, []);

    anim.start();

    return () => {
      anim.stop();
    };
  }, []);

  return (
    <Arc
      x={x}
      y={y}
      outerRadius={size}
      innerRadius={size - 2}
      angle={100}
      fill="#0af"
      rotation={angle}
    />
  );
};

export default SpinCircle;
