import React, { useState, useCallback } from 'react';
import { Group, Rect } from 'react-konva';
import { useStore } from '../../utils/store';
import { isTouch } from '../../utils/isTouch';
import { sendAnalytics, EVENTS } from '../../utils/analytics';
import { INPAINTING_ENDPOINT } from '../../const';
import ObjectMask from '../ObjectMask';
import SpinCircle from '../SpinCircle';

import './ObjectLayer.scss';

const tagSize = 20;

function getRelativeSize(partialOrig, totalOrig, newOrig) {
  return (partialOrig / totalOrig) * newOrig;
}

const ObjectLayer = ({ tag, item, itemIndex, imageData }) => {
  const { store, updateStore } = useStore();
  const [toggle, setToggle] = useState(null); // 'init' or 'hover' or 'clicked'

  const onObjectRemoval = newImage => {
    updateStore({
      layerData: [
        ...(store.layerData || []),
        {
          tag,
          itemIndex,
          mask: item.mask,
          imageBlob: newImage
        }
      ],
      redoData: [] // reset redo stack
    });
    hideMask();
  };

  const handleTagClick = () => {
    if (toggle !== 'hover') {
      showMask();
      return;
    }

    console.log('Inpainting: ' + tag, item);
    sendAnalytics({ ...EVENTS.item_erase, additional_info: tag });

    setToggle('clicked');

    const categories = store.layerData?.map(i => i.tag) || [];
    const masks = store.layerData?.map(i => i.mask) || [];

    const body = {
      publicId: imageData.publicId,
      category: [tag, ...categories].join('_'),
      mask: JSON.stringify({ masks: [item.mask, ...masks] })
    };

    let startTime = Date.now();

    try {
      fetch(INPAINTING_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then(response => {
          sendAnalytics({ ...EVENTS.item_erase_done, additional_info: Date.now() - startTime });
          return response.blob();
        })
        .then(data => {
          onObjectRemoval(data);
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleRefinedMask = mask => {
    updateStore({
      maskData: {
        ...(store.maskData || {}),
        [itemIndex]: mask
      }
    });
  };

  const handleCursor = e => {
      const stage = e?.target.getStage();
      if (stage) {
        if (e.type === "mouseover") {
          stage.container().style.cursor = "crosshair";
        } else {
          stage.container().style.cursor = "default";
        }
      }
  }

  const initMask = useCallback(() => {
    setToggle('init');
  }, []);

  const showMask = useCallback((e) => {
    handleCursor(e);
    setToggle('hover');

    if (isTouch) {
      updateStore({ activeLayer: itemIndex });
    }
  }, [itemIndex, updateStore]);

  const hideMask = useCallback((e) => {
    if (toggle === 'hover') {
      handleCursor(e);
      setToggle('init');

      if (isTouch && store.activeLayer === itemIndex) {
        updateStore({ activeLayer: undefined });
      }
    }
  }, [toggle, itemIndex, updateStore, store.activeLayer]);

  const [maskHeight, maskWidth] = item.mask.size;
  const { width: imageWidth, height: imageHeight } = imageData;

  const itemRefinedMask = store.maskData && store.maskData[itemIndex];
  const wasRemoved = store.layerData?.some(i => i.itemIndex === itemIndex);

  return (
    <>
      {toggle && !wasRemoved && (
        <ObjectMask
          mask={itemRefinedMask || item.mask}
          imageData={imageData}
          active={toggle === 'hover' || toggle === 'clicked'}
          setRefinedMask={handleRefinedMask}
          hideMask={hideMask}
          {...(isTouch
            ? {
                onTap: handleTagClick
              }
            : {
                onClick: handleTagClick,
                onMouseOver: showMask,
                onMouseLeave: hideMask
              })}
        />
      )}
      <Group
        x={getRelativeSize(item['bounding-box'][0], maskWidth, imageWidth)}
        y={getRelativeSize(item['bounding-box'][1], maskHeight, imageHeight)}
        visible={!wasRemoved}
      >
        {!toggle && (
          <Rect
            // stroke="#fff"
            x={0}
            y={0}
            {...(isTouch ? { onTap: showMask } : { onMouseEnter: initMask })}
            width={getRelativeSize(item['bounding-box'][2], maskWidth, imageWidth)}
            height={getRelativeSize(item['bounding-box'][3], maskHeight, imageHeight)}
          />
        )}
        {toggle === 'clicked' && (
          <SpinCircle
            x={getRelativeSize(item['bounding-box'][2] / 2, maskWidth, imageWidth)}
            y={getRelativeSize(item['bounding-box'][3] / 2, maskWidth, imageWidth)}
            size={tagSize}
            stroke="#fff"
            strokeWidth={3}
          />
        )}
      </Group>
    </>
  );
};

export default ObjectLayer;
