import React from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import useMount from 'react-use/lib/useMount';
import { useDropzone } from 'react-dropzone';
import { useStore, initialStore } from '../../utils/store';
import { isTouch } from '../../utils/isTouch';
import { sendAnalytics, EVENTS } from '../../utils/analytics';
import { OBJECT_DETECTION_ENDPOINT, UPLOAD_IMAGE_ENDPOINT } from '../../const';
import { getImagePath } from '../../utils/cloudinary';
import { removeDuplicates } from '../../utils/results';

import './Upload.scss';

const examples = [
  {
    publicId: 'Inputs/usey90ksiiflodgss4zd',
    label: 'Photobombing',
    alt: 'Photo by Diana Parkhouse'
  },
  {
    publicId: 'Inputs/ni8wg8djqohs0azxsude',
    label: 'Product Cleanup',
    alt: 'Photo by Kyle Glenn'
  },
  {
    publicId: 'Inputs/fbk0hhppyxnqafucrxa3',
    label: 'Object Removal',
    alt: ''
  }
];

const Upload = () => {
  const qsPublicID = queryString.parse(window.location.search).pid;
  const navigate = useNavigate();
  const { updateStore } = useStore();

  useMount(() => {
    // Reset app state
    updateStore(initialStore);

    if (qsPublicID) {
      onUploaded({ publicId: qsPublicID });
    }
  });

  const onUploaded = async uploadInfo => {
    let url = new URL(window.location.href);
    url.searchParams?.set('pid', uploadInfo.publicId);

    console.groupCollapsed("Uploaded image:", uploadInfo.publicId);
    console.log('publicId:', uploadInfo.publicId);
    console.log('directPath:', url?.href);
    console.groupEnd();

    sendAnalytics({ ...EVENTS.asset_proccessing, additional_info: uploadInfo.publicId });
    updateStore({
      uploadInProgress: false,
      objectDetectionInProgress: true,
      imageData: uploadInfo
    });
    try {
      fetch(OBJECT_DETECTION_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ publicId: uploadInfo.publicId })
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          data.tags = removeDuplicates(data.tags);
          updateStore({
            objectDetectionInProgress: false,
            objectData: data
          });
          navigate({
            pathname: '/edit',
            search: window.location.search
          });
        });
    } catch (error) {
      console.error('Error scanning file:', error);
    }
  };

  const onDrop = async acceptedFiles => {
    sendAnalytics(EVENTS.asset_upload);
    updateStore({ uploadInProgress: true });
    try {
      const formData = new FormData();
      formData.append('image', acceptedFiles[0]);
      fetch(UPLOAD_IMAGE_ENDPOINT, {
        method: 'POST',
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          sendAnalytics({ ...EVENTS.asset_upload_done, additional_info: data.publicId });
          onUploaded(data);
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    maxFiles: 1,
    accept: { 'image/*': [] },
    onDrop
  });

  const errors = fileRejections.map(i => i.errors.map(err => err.message)).flat();
  const uniqueErrors = Array.from(new Set(errors));

  return (
    <div className="upload-page">
      <div className="intro">
        <div className="container">
          <h1>Remove Unwanted Objects from Your Photos in Seconds.<br />It’s Free!</h1>
          <img src={getImagePath('erase-it-gif.gif', false)} alt="" />
        </div>
      </div>

      <div className="upload">
        <div className="container">
          <h4 className="try">Try it now</h4>
          <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the image here</p>
            ) : (
              <p>{isTouch ? 'Tap to select an image' : 'Drag and drop image or click'}</p>
            )}
            {uniqueErrors.length > 0 && uniqueErrors.map(err => <p className="error">{err}</p>)}
          </div>
          <h4>Or try one of these examples:</h4>
          <div className="samples">
            {examples.map(ex => (
              <button
                type="button"
                key={ex.publicId}
                onClick={() => {
                  sendAnalytics({ ...EVENTS.demo_image_select, additional_info: ex.publicId });
                  onUploaded({ publicId: ex.publicId });
                }}
              >
                <img
                  src={getImagePath(ex.publicId, 'w_180')}
                  alt={ex.alt}
                  width="180"
                  height="120"
                />
                <p>{ex.label}</p>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upload;
