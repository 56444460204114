import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiMenuRight } from '@mdi/js';
import { useStore } from '../../utils/store';
import Feedback from '../Feedback';

import './Sidebar.scss';

const Sidebar = () => {
  const { store } = useStore();
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  }

  return (
    <aside className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <Routes>
        <Route
          path="/edit"
          element={store.layerData?.length > 0 && (
            <div className="sidebar-inner">
              <button className="sidebar-toggle" onClick={toggleSidebar}><Icon path={mdiMenuRight} size={1} /></button>
              <Feedback />
            </div>
          )}
        />
      </Routes>
    </aside>
  );
};

export default Sidebar;
