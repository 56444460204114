import React, { useState, useEffect } from 'react';
import { Image } from 'react-konva';

import './ImageLayer.scss';

const ImageLayer = ({ layer, width, height }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    const img = new window.Image();
    img.src = URL.createObjectURL(layer.imageBlob);
    img.addEventListener('load', () => setImage(img));
  }, [layer]);

  return (
    <Image
      image={image}
      width={width}
      height={height}
      preventDefault={false}
    />
  );
};

export default ImageLayer;