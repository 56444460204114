import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiPlus, mdiCompare, mdiUndo, mdiRedo } from '@mdi/js';
import { useStore } from '../../utils/store';
import { sendAnalytics, EVENTS } from '../../utils/analytics';

import './Footer.scss';

const Footer = () => {
  const { store, updateStore } = useStore();

  const toggleOriginal = e => {
    if (e.type === 'mousedown' || e.type === 'touchstart') {
      sendAnalytics(EVENTS.asset_view_original);
    }
    updateStore({ showingOriginal: !store.showingOriginal });
  };

  const handleUndo = () => {
    const currentIndex = store.layerData.length - 1;
    const lastAction = store.layerData[currentIndex];

    updateStore({
      layerData: store.layerData.slice(0, currentIndex),
      redoData: [...(store.redoData || []), lastAction]
    });

    sendAnalytics(EVENTS.item_erase_undo);
  };

  const handleRedo = () => {
    const currentIndex = store.redoData.length - 1;
    const lastAction = store.redoData[currentIndex];

    updateStore({
      layerData: [...(store.layerData || []), lastAction],
      redoData: store.redoData.slice(0, currentIndex)
    });

    sendAnalytics(EVENTS.item_erase_redo);
  };

  return (
    <footer className="footer">
      <Routes>
        <Route
          path="/edit"
          element={
            <div className="footer-inner">
              <Link
                className="btn inverted"
                to="/"
                onClick={() => sendAnalytics(EVENTS.asset_replace)}
              >
                <Icon path={mdiPlus} size={1} />
                <span className="label">Replace image</span>
              </Link>
              <button
                className="btn inverted light"
                onClick={handleUndo}
                disabled={!store.layerData?.length}
              >
                <Icon path={mdiUndo} size={1} />
                <span className="label">Undo</span>
              </button>
              <button
                className="btn inverted light"
                onClick={handleRedo}
                disabled={!store.redoData?.length}
              >
                <Icon path={mdiRedo} size={1} />
                <span className="label">Redo</span>
              </button>
              <button
                className="btn inverted light"
                onMouseDown={toggleOriginal}
                onMouseUp={toggleOriginal}
                onTouchEnd={toggleOriginal}
              >
                <Icon path={mdiCompare} size={1} />
                <span className="label">View original</span>
              </button>
            </div>
          }
        />
      </Routes>
    </footer>
  );
};

export default Footer;
