export function b64DecodeUnicode(str) {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}

export function rleFrString(s) {
  let m = 0,
    p = 0,
    k;
  let x;
  let more;
  let cnts = [];
  s = b64DecodeUnicode(s);
  while (s[p]) {
    x = 0;
    k = 0;
    more = 1;
    while (more) {
      const c = s.charCodeAt(p) - 48;
      x |= (c & 0x1f) << (5 * k);
      more = c & 0x20;
      p++;
      k++;
      if (!more && c & 0x10) {
        x |= -1 << (5 * k);
      }
    }
    if (m > 2) {
      x += cnts[m - 2];
    }
    cnts[m++] = x >>> 0;
  }
  return cnts;
}

export function createMaskImage(rle, width, height) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const context = canvas.getContext('2d');
  const imageData = context.createImageData(width, height);
  const data = imageData.data;

  function setPixel(x, y, color) {
    const idx = (y * width + x) * 4;

    data[idx] = color[0]; // R
    data[idx + 1] = color[1]; // G
    data[idx + 2] = color[2]; // B
    data[idx + 3] = color[3]; // A
  }

  let y = 0;
  let x = 0;

  for (let j = 0; j < rle.length; j++) {
    const bits = rle[j];
    for (let k = 0; k < bits; k++) {
      setPixel(x, y, j % 2 ? [255, 0, 0, 120] : [0, 0, 0, 60]);

      y++;
      if (y === height) {
        y = 0;
        x++;
      }
    }
  }

  context.putImageData(imageData, 0, 0);
  return canvas.toDataURL('image/png');
}

export function mask2img(mask) {
  return new Promise((resolve, reject) => {
    const [maskHeight, maskWidth] = mask.size;
    const rle = rleFrString(mask.counts);
    const img = new window.Image();
    img.addEventListener('load', () => resolve(img));
    img.addEventListener('error', reject);
    img.src = createMaskImage(rle, maskWidth, maskHeight);
  });
}