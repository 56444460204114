import React, { useState, useEffect, useRef } from 'react';
import { Image, Rect } from 'react-konva';
import { mask2img } from './ObjectMask.utils';
import { REFINE_MASK_ENDPOINT } from '../../const';
import { isTouch } from '../../utils/isTouch';

import './ObjectMask.scss';

const ObjectMask = ({ mask, imageData, active, setRefinedMask, ...props }) => {
  const imageRef = useRef(null);
  const [image, setImage] = useState(null);
  const [creatingMask, setCreatingMask] = useState(false);
  const { width: imageWidth, height: imageHeight } = imageData;

  useEffect(() => {
    const handleRefineMask = () => {
      const body = {
        publicId: imageData.publicId,
        mask: JSON.stringify({ mask })
      };

      return fetch(REFINE_MASK_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .catch(error => {
          console.error('Error refining mask:', error);
          throw error;
        });
    };

    if (!image && !creatingMask) {
      setCreatingMask(true);

      mask2img(mask).then(img => {
        setImage(img);
        cacheMask();
        handleRefineMask().then(data => {
          if (data.mask) {
            mask2img(data.mask[0].mask).then(refinedImg => {
              setImage(refinedImg); // ToDo: test
            });
            setRefinedMask(data.mask[0].mask);
          }
        });
      });
    }
  }, [active, creatingMask, image, imageData.publicId, mask, setRefinedMask]);

  const hideMask = () => {
    props.hideMask();
  };

  const cacheMask = () => {
    const imgRef = imageRef.current;
    if (imgRef) {
      imgRef.clearCache();
      imgRef.cache();
      imgRef.drawHitFromCache(100);
    }
  };

  useEffect(() => {
    cacheMask();
  }, [image, imageWidth, imageHeight]);

  return (
    <>
      {isTouch && (
        <Rect
          x={0}
          y={0}
          width={imageWidth}
          height={imageHeight}
          onTap={hideMask}
          visible={active}
        />
      )}
      <Image
        ref={imageRef}
        image={image}
        width={imageWidth}
        height={imageHeight}
        opacity={active ? 1 : 0}
        preventDefault={false}
        {...props}
      />
    </>
  );
};

export default ObjectMask;
