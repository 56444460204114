import { Cloudinary } from '@cloudinary/url-gen';

import { CLOUDNAME } from '../const';

const cld = new Cloudinary({
  cloud: {
    cloudName: CLOUDNAME
  }
});

export function getImagePath(publicId, transform) {
  const cldImage = cld.image(publicId);

  if (transform) {
    cldImage.addTransformation(transform);
  } else if (transform !== false) {
    cldImage.addTransformation('t_jpg');
  }

  return cldImage.toURL();
}
