import React, { useState, useRef } from 'react';
import useMount from 'react-use/lib/useMount';
import Icon from '@mdi/react';
import { mdiDownload, mdiSend, mdiCheckboxMarked, mdiCheckboxBlankOutline } from '@mdi/js';
import { useStore } from '../../utils/store';
import { sendAnalytics, EVENTS } from '../../utils/analytics';
import Legal from '../Legal';

import './Feedback.scss';

const Feedback = () => {
  const { store, updateStore } = useStore();
  const [rating, setRating] = useState();
  const [agreed, setAgreed] = useState(false);
  const textareaRef = useRef(null);
  const emailRef = useRef(null);

  useMount(() => {
    // trigger canvas resize
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 250);
  });

  const handleRating = i => {
    setRating(i);
    sendAnalytics({ ...EVENTS.rating_form_submit, additional_info: i });
  };

  const handleFeedback = () => {
    sendAnalytics({ ...EVENTS.feedback_text_submit, additional_info: textareaRef.current.value });
    textareaRef.current.value = 'Thanks, your feedback was sent :)';
    setTimeout(() => {
      textareaRef.current.value = '';
    }, 3000);
  };

  const handleEmail = () => {
    sendAnalytics({ ...EVENTS.email_submit, additional_info: emailRef.current.value });
    emailRef.current.value = 'Thanks!';
    setTimeout(() => {
      emailRef.current.value = '';
    }, 3000);
  };

  const handleDownload = () => {
    sendAnalytics(EVENTS.asset_download);
  };

  const toggleLegal = e => {
    e?.preventDefault();
    updateStore({ showLegal: !store.showLegal });
  };

  const showShareResult = () => {
    sendAnalytics(EVENTS.product_share_result);
    updateStore({ showShareResult: true });
  };

  const imageLayer = store.layerData && store.layerData.slice(-1)[0];

  return (
    <div className="feedback">
      <h3>Rate the result</h3>
      <div className="ratings">
        <div className="buttons">
          {[1, 2, 3, 4, 5].map(i => (
            <button
              key={i}
              className={i === rating ? 'active' : undefined}
              onClick={() => handleRating(i)}
            >
              {i}
            </button>
          ))}
        </div>
        <div className="labels">
          <span>Very unsatisfied</span>
          <span>Very satisfied</span>
        </div>
      </div>

      <h3>Did the app meet your expectations?</h3>
      <textarea className="input" ref={textareaRef} placeholder={'Let us know...'}></textarea>
      <button className="btn inverted send-feedback" onClick={handleFeedback}>
        <Icon path={mdiSend} size={1} />
      </button>

      <h3>Enter your email and hear about our new AI tools</h3>
      <div className="email">
        <input
          className="input email-input"
          type="email"
          ref={emailRef}
          placeholder="your@email.com"
          required
        />
        <button className="btn inverted send-email" onClick={handleEmail}>
          <Icon path={mdiSend} size={1} />
        </button>
      </div>

      <h3>Download result</h3>
      <label className="agree">
        <input type="checkbox" onChange={e => setAgreed(e.target.checked)} />{' '}
        <Icon className="unchecked" path={mdiCheckboxBlankOutline} size={1} />
        <Icon className="checked" path={mdiCheckboxMarked} size={1} />
        <span>
          I agree to{' '}
          <a href="#legal" onClick={toggleLegal}>
            Cloudinary Labs Legal Disclaimer
          </a>
        </span>
      </label>

      <div className="actions">
        <a
          className="btn"
          disabled={!agreed}
          download={agreed}
          href={agreed ? URL.createObjectURL(imageLayer.imageBlob) : '#'}
          onClick={handleDownload}
          title={!agreed ? 'You must agree to Cloudinary Labs Legal Disclaimer' : ''}
        >
          <Icon path={mdiDownload} size={1} />
          Download
        </a>
        <button
          className="btn inverted"
          onClick={showShareResult}
          disabled={!agreed}
          title={!agreed ? 'You must agree to Cloudinary Labs Legal Disclaimer' : ''}
        >
          <Icon path={mdiSend} size={1} />
          Tell a friend
        </button>
      </div>

      <a
        href="https://cloudinary.com/users/register_free?ref=eraseit"
        rel="noreferrer noopener"
        target="_blank"
        className="sign-up"
      >
        Free sign up to Cloudinary
      </a>

      {store.showLegal && <Legal onClose={toggleLegal} />}
    </div>
  );
};

export default Feedback;
