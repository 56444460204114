export const EVENTS = {
  asset_upload: {
    event_name: 'asset_upload',
    main_screen_name: 'welcome screen'
  },
  demo_image_select: {
    event_name: 'demo_image_select',
    main_screen_name: 'welcome screen'
  },
  // contact_navigate: {
  //   event_name: 'contact_navigate',
  //   main_screen_name: 'welcome screen'
  // },
  asset_upload_progress: {
    event_name: 'asset_upload_progress',
    main_screen_name: 'welcome screen'
  },
  asset_proccessing: {
    event_name: 'asset_proccessing',
    main_screen_name: 'welcome screen'
  },
  error_msg: {
    event_name: 'error_msg',
    main_screen_name: 'welcome screen'
  },
  // item_selection: {
  //   event_name: 'item_selection',
  //   main_screen_name: 'asset editor screen'
  // },
  asset_replace: {
    event_name: 'asset_replace',
    main_screen_name: 'asset editor screen'
  },
  item_erase_undo: {
    event_name: 'item_erase_undo',
    main_screen_name: 'asset editor screen'
  },
  item_erase_redo: {
    event_name: 'item_erase_redo',
    main_screen_name: 'asset editor screen'
  },
  asset_view_original: {
    event_name: 'asset_view_original',
    main_screen_name: 'asset editor screen'
  },
  item_erase: {
    event_name: 'item_erase',
    main_screen_name: 'asset editor screen'
  },
  item_erase_progress: {
    event_name: 'item_erase_progress',
    main_screen_name: 'asset editor screen'
  },
  asset_download: {
    event_name: 'asset_download',
    main_screen_name: 'asset editor screen'
  },
  rating_form_submit: {
    event_name: 'rating_form_submit',
    main_screen_name: 'asset editor screen'
  },
  feedback_text_submit: {
    event_name: 'feedback_text_submit',
    main_screen_name: 'asset editor screen'
  },
  email_submit: {
    event_name: 'email_submit',
    main_screen_name: 'asset editor screen'
  },
  product_share: {
    event_name: 'product_share',
    main_screen_name: 'welcome screen'
  },
  product_share_result: {
    event_name: 'product_share_result',
    main_screen_name: 'asset editor screen'
  },
  product_share_social_intent: {
    event_name: 'product_share_social_intent',
    main_screen_name: 'social share'
  },
  product_share_copy_link: {
    event_name: 'product_share_copy_link',
    main_screen_name: 'social share'
  }
};

export const sendAnalytics = ({
  event = 'ga4_event',
  product_name = 'inpainting',
  event_name,
  main_screen_name,
  additional_info
}) => {
  window.dataLayer.push({
    event,
    event_name,
    product_name,
    ...(main_screen_name && { main_screen_name }),
    ...(additional_info && { additional_info })
  });
};
