
export function removeDuplicates(obj) {
  const flat = Object.entries(obj).map(([key, val]) => {
    // Flatten, and add a unique ID to each object
    return val.map((item, idx) => ({...item, tag: key, itemIndex: key + idx }))
  }).flat();

  for (let i = 0; i < flat.length; i++) {
    const currentObj = flat[i];
    // Loop through the rest of the objects in the array
    for (let j = i + 1; j < flat.length; j++) {
      const compareObj = flat[j];
      // Calculate the similarity of the bounding boxes
      const similarity = calculateBoundingBoxSimilarity(currentObj["bounding-box"], compareObj["bounding-box"]);
      // If the similarity is over 95%, mark the one with lower confidence as duplicate
      if (similarity > 0.95) {
        if (currentObj["confidence"] > compareObj["confidence"]) {
          compareObj["duplicate"] = true;
        } else {
          currentObj["duplicate"] = true;
        }
      }
    }
  }

  // Filter out the duplicates and objects with very low confidence
  const filered = flat.filter(item => item.confidence > 0.2).filter(item => !item.duplicate);

  // Sort the objects by bounding-box size so that smaller ones get a higher "z-index"
  const sorted = filered.sort((a, b) => {
    const areaA = a["bounding-box"][2] * a["bounding-box"][3];
    const areaB = b["bounding-box"][2] * b["bounding-box"][3];

    return areaB - areaA;
  });

  return sorted;
}

function calculateBoundingBoxSimilarity(bb1, bb2) {
  // calculate the area of intersection and union of the two bounding boxes
  let x1 = Math.max(bb1[0], bb2[0]);
  let y1 = Math.max(bb1[1], bb2[1]);
  let x2 = Math.min(bb1[0] + bb1[2], bb2[0] + bb2[2]);
  let y2 = Math.min(bb1[1] + bb1[3], bb2[1] + bb2[3]);

  let intersection = Math.max(0, x2 - x1) * Math.max(0, y2 - y1);
  let union = bb1[2] * bb1[3] + bb2[2] * bb2[3] - intersection;

  // calculate the similarity using the Jaccard index
  return intersection / union;
}
