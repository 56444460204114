import React, { useEffect, useRef, useState } from 'react';
import Icon from '@mdi/react';
import { mdiContentCopy } from '@mdi/js';
import { sendAnalytics, EVENTS } from '../../utils/analytics';
import { useStore } from '../../utils/store';
import { SHARE_URL, SHARE_IMAGE_ENDPOINT } from '../../const';
import Modal from '../Modal';

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share';

import './Share.scss';

const Share = ({ onClose }) => {
  const { store } = useStore();
  const inputRef = useRef(null);
  const [shareImage, setShareImage] = useState();

  const isShareResult = store.showShareResult;

  useEffect(() => {
    const handleShareImage = () => {
      const categories = store.layerData?.map(i => i.tag) || [];

      const body = {
        publicId: store.imageData.publicId,
        category: categories.join('_')
      };

      return fetch(SHARE_IMAGE_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .catch(error => {
          console.error('Error:', error);
          throw error;
        });
    };

    if (!shareImage && isShareResult && store.layerData.length > 0 && store.imageData?.publicId) {
      handleShareImage().then(data => {
        setShareImage(data.sharedImageUrl);
      });
    }
  }, [isShareResult, shareImage, store.imageData?.publicId, store.layerData]);

  function handleClick() {
    inputRef.current.select();
    document.execCommand('copy');
    inputRef.current.blur();
    inputRef.current.value = 'Copied...';
    setTimeout(() => {
      inputRef.current.value = SHARE_URL;
    }, 1000);
    sendAnalytics(EVENTS.product_share_copy_link);
  }

  function handleShareClick(shareTo) {
    sendAnalytics({ ...EVENTS.product_share_social_intent, additional_info: shareTo });
  }

  const shareUrl = shareImage && isShareResult ? shareImage : SHARE_URL;
  const shareTitle =
    'Erase It | Cloudinary Labs - Detect and remove object from your own photos with the help of Cloudinary AI @ ' +
    SHARE_URL;

  return (
    <Modal className="share" onClose={onClose}>
      <h3>Tell a friend</h3>

      {shareImage && isShareResult && (
        <img className="share-img" src={shareImage} alt="" width="440" height="147" />
      )}

      <div className="share-buttons">
        <FacebookShareButton
          url={shareUrl}
          quote={shareTitle}
          beforeOnClick={() => handleShareClick('Facebook')}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <LinkedinShareButton
          url={shareUrl}
          title={shareTitle}
          beforeOnClick={() => handleShareClick('Linkedin')}
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <TelegramShareButton
          url={shareUrl}
          title={shareTitle}
          beforeOnClick={() => handleShareClick('Telegram')}
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
        <TwitterShareButton
          url={shareUrl}
          title={shareTitle}
          beforeOnClick={() => handleShareClick('Twitter')}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={shareTitle}
          beforeOnClick={() => handleShareClick('Whatsapp')}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>

      <div className="share-url">
        <input ref={inputRef} type="text" value={SHARE_URL} readOnly />
        <button onClick={handleClick}>
          <Icon path={mdiContentCopy} size={0.7} />
        </button>
      </div>
    </Modal>
  );
};

export default Share;
