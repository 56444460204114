export const BASENAME = window.location.pathname.includes('/erase-it') ? '/erase-it' : '/';

export const PRODUCTION_CLIENT_URL = 'https://production-smart-ai-transformations-bff.cloudinary.com';
export const STAGING_CLIENT_URL = 'https://staging-smart-ai-transformations-bff.cloudinary.com';
export const apiClientUrl = window.location.hostname === "localhost" ? STAGING_CLIENT_URL : PRODUCTION_CLIENT_URL;

export const UPLOAD_IMAGE_ENDPOINT = `${apiClientUrl}/upload-image`;
export const OBJECT_DETECTION_ENDPOINT = `${apiClientUrl}/inpainting/detect-objects`;
export const INPAINTING_ENDPOINT = `${apiClientUrl}/inpainting/remove-object`;
export const REFINE_MASK_ENDPOINT = `${apiClientUrl}/inpainting/refine-mask`;
export const SHARE_IMAGE_ENDPOINT = `${apiClientUrl}/inpainting/shared-image`;

export const CLOUDNAME = 'smart-ai-transformations-inpainting';

export const SHARE_URL = 'https://erase-it.cloudinary.com/';

